// Here you can add other styles
.blink_me {
  animation: blinker 0.2s linear;
  animation-iteration-count: 3;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

header.app-header.navbar {
  background-color: hsl(204deg 70% 67%);
  filter: invert(1) brightness(1.7);
}
